import { SearchIcon } from "@chakra-ui/icons"
import { Box, Flex, Image, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react"
import styles from "../Corporate.module.css";

export const CorporateLandingComponent: React.FC<{ handleLogin: (arg: boolean) => void }> = ({ handleLogin }) => {
    return (
        <Box width={"100%"} background={"rgba(254, 29, 143, 0.03)"}>
            <Box width={"70%"} margin={"auto"} paddingTop={"140px"} paddingBottom={"106px"}>
                <Box>
                    <Flex flexDir={"row"} justifyContent={"center"}>
                        <Box className={styles.gradientBorderLeftBox} marginTop="40px"
                        ></Box>
                        <Box>
                            <Text
                                fontFamily="Poppins"
                                fontWeight={600}
                                fontSize="48px"
                                lineHeight="70px"
                                textAlign="center"
                                letterSpacing="0px"
                                color={"rgba(18, 195, 149, 1)"}
                            >
                                Write up about our Clientele
                            </Text>
                            <Text
                                width={"85%"}
                                margin={"auto"}
                                fontFamily="Poppins"
                                fontWeight="400"
                                fontSize="16px"
                                lineHeight="24px"
                                letterSpacing="0%"
                                textAlign={"center"}
                                mt="32px"
                                mx={"auto"}
                            >
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                            </Text>
                            <Box width="100%" maxWidth="70%" margin="60px auto">
                                <InputGroup>
                                    <Input
                                        height="69px"
                                        type="text"
                                        placeholder="Search by company name"
                                        focusBorderColor="rgba(18, 195, 149, 1)"
                                        borderRadius="10px"
                                        border="1px solid rgba(0, 0, 0, 0.28)"
                                        boxShadow="0px 8px 20.9px 0px rgba(254, 29, 143, 0.13)"
                                        pr={10} // Padding to avoid text overlap with icon
                                        _hover={{ borderColor: 'green.300' }}
                                        bg={"#fff"}
                                        variant="outline"
                                        fontFamily="Poppins"
                                        fontSize="18px"
                                        fontWeight={400}
                                        lineHeight="24px"
                                        letterSpacing="0.5px"
                                        color={"rgba(0, 0, 0, 0.5)"}
                                    />
                                    <InputRightElement
                                        height="100%"
                                        pointerEvents="none"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <SearchIcon color="rgba(18, 195, 149, 1);" width={"30px"} height={"30px"} mr={"23px"} />
                                    </InputRightElement>
                                </InputGroup>
                            </Box>
                        </Box>
                        <Box className={styles.gradientBorderRightBox} marginTop="40px"></Box>
                    </Flex>
                    <Box width={"90%"} margin="auto" mt={"10px"}>
                        <Flex flex="row" gap={25} justifyContent={"center"} >
                            <Box
                                background="rgba(255, 255, 255, 1)"
                                borderRadius={"15px"}
                                boxShadow="0px 4px 17.1px 0px rgba(254, 29, 143, 0.12)"
                                padding={"20px"}
                                onClick={()=>handleLogin(true)}
                            >
                                <Image src="Images/persistent.svg" alt='persistent image' />
                            </Box>
                            <Box
                                background="rgba(255, 255, 255, 1)"
                                borderRadius={"15px"}
                                boxShadow="0px 4px 17.1px 0px rgba(254, 29, 143, 0.12)"
                                padding={"20px"}
                                onClick={()=>handleLogin(true)}
                            >
                                <Image src="Images/persistent.svg" alt='persistent image' />
                            </Box>
                            <Box
                                background="rgba(255, 255, 255, 1)"
                                borderRadius={"15px"}
                                boxShadow="0px 4px 17.1px 0px rgba(254, 29, 143, 0.12)"
                                padding={"20px"}
                                onClick={()=>handleLogin(true)}
                            >
                                <Image src="Images/persistent.svg" alt='persistent image' />
                            </Box>
                            <Box
                                background="rgba(255, 255, 255, 1)"
                                borderRadius={"15px"}
                                boxShadow="0px 4px 17.1px 0px rgba(254, 29, 143, 0.12)"
                                padding={"20px"}
                                onClick={()=>handleLogin(true)}
                            >
                                <Image src="Images/persistent.svg" alt='persistent image' />
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}