import { 
    Flex, 
    Box, 
    Text, 
    Popover, 
    PopoverTrigger, 
    PopoverContent, 
    PopoverBody,  
  } from '@chakra-ui/react';
  import { LockIcon, ChevronDownIcon } from '@chakra-ui/icons';
  // Add any other icons you need for the dropdown boxes
  import styles from "../Corporate.module.css";
import { BsBuildingFillExclamation } from 'react-icons/bs';
import { IoPerson } from 'react-icons/io5';

  export const LoginButtonWithDropdown = () => {
    return (
      <Popover trigger="hover" placement="bottom-end">
        <PopoverTrigger>
          <Flex 
            alignItems="center" 
            gap={2} 
            bg="rgba(254, 29, 143, 1)" 
            borderRadius="92px" 
            padding="11.5px 15px"
            cursor="pointer"
            boxSize='border-box'
            _hover={{background:"#12C395;"}}
            transition={"all 0.5s ease"}
          >
            <LockIcon boxSize={5} color="#fff" />
            <Text className={styles.navTextStyle} style={{ color: "#fff" }}>Login</Text>
            <ChevronDownIcon boxSize={6} color="#fff" />
          </Flex>
        </PopoverTrigger>
        
        <PopoverContent 
          width="auto" 
          border="none" 
          boxShadow="0px 4px 20px rgba(0, 0, 0, 0.15)"
          mt={"-5px"}
        >
          <PopoverBody 
            p={"10px"}
            background="#12C395"
            borderRadius={"10px"}
        >
            <Flex
              align="center"
              gap={"10px"}
              cursor={"pointer"}
            >
              <BsBuildingFillExclamation  color="#fff" size={20} />
              <Text 
                color={"#fff"}
                fontFamily={"Poppins"}
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"14px"}
                py={"13px"}
                letterSpacing={"0.5px"}
            >
                Corporate Login
                </Text>
            </Flex>
            <Box width="100%" height={"1px"} borderBottom="1px solid" borderColor="gray.100"></Box>
            <Flex
              align="center"
              gap={"10px"}
              cursor={"pointer"}        
            >
              <IoPerson color="#fff" size={20}/>
              <Text 
                color={"#fff"}
                fontFamily={"Poppins"}
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"24px"}
                py={"13px"}
                letterSpacing={"0.5px"}
            >
                Customer Login
            </Text>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };