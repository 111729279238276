import { EmailIcon } from "@chakra-ui/icons"
import { Box, Flex, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"

export const EmailInput = () => {
    return (
        <InputGroup>
            <InputLeftElement
                pointerEvents="none"
                width="auto"
                left="25px"
                alignItems={"center"}
                height={"100%"}
            >
                <Flex align="center" gap="24px" height={"100%"}>
                    <EmailIcon color="rgba(0, 0, 0, 0.31)" boxSize={5} />
                    <Box
                        h="25px"
                        w="1px"
                        bg="rgba(0, 0, 0, 0.28)"
                        border="1px solid rgba(223, 223, 223, 1)"
                    />
                </Flex>
            </InputLeftElement>
            <Input
                type="email"
                placeholder="xyz@persistent.com"
                pl={20}
                paddingTop={"21px"}
                paddingBottom={"14px"}
                focusBorderColor="green.500"
                _hover={{ borderColor: 'gray.500' }}
                background={"#fff"}
                border="1px solid rgba(0, 0, 0, 0.28)"
                borderRadius={"10px"}
                boxShadow="0px 8px 20.9px 0px rgba(254, 29, 143, 0.13)"
                fontFamily="Poppins"
                fontWeight={400}
                fontSize="16px"
                lineHeight="24px"
                marginLeft={"5px"}
                height={"auto"}
            />
        </InputGroup>
    )
}