import {
    Box
} from '@chakra-ui/react';
import styles from "./Corporate.module.css";
import { Navbar } from './Components/Navbar.component';
import { Footer } from './Components/Footer.component';
import { CorporateLoginComponent } from './Components/Login.component';
import { CorporateLandingComponent } from './Components/Landing.component';
import { useState } from 'react';

const Corporate = () => {
    const [isLogin, setIsLogin] = useState(false);
    const handleLogin = (value: boolean) => setIsLogin(value);
    return (
        <Box className={styles.wrapper}>
            <Box className={styles.container}>
                {/* Navbar */}
                <Navbar />
                {/* Main Content */}
                { !isLogin ? <CorporateLandingComponent handleLogin={handleLogin}/> : <CorporateLoginComponent />}
                {/* Footer */}
                <Footer />
            </Box >
        </Box >
    )
};

export default Corporate;