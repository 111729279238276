
import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    HStack,
    Text, // Import Text component from Chakra UI
    IconButton,
    Image,
    Progress,
    Stack,
    Avatar,
    Button,
    Heading,
    Tooltip,
} from "@chakra-ui/react";
import { BsQrCode } from "react-icons/bs";
import Dashboardstyle from '../../DashBoard.module.css';
import { PersonalInfo } from '../../../../Helper/BenInterface';
import AlertDialogCompo from '../AlertDialog/AlertDialogCompo';
import QrCompo from '../AlertDialog/QrModal';
import { useNavigate } from 'react-router-dom';
import { EditIcon, ViewIcon } from '@chakra-ui/icons';
import { getDocument } from '../../../../http';

interface OtpModalProps {
    personalInfo: PersonalInfo;
    id: string;
    isSmallerThan375: boolean;
    progress: number;
    lat: string | null;
    lng: string | null
}
const CardComponent: React.FC<OtpModalProps> = ({ personalInfo, id, isSmallerThan375, progress, lat, lng }) => {
  
    const [isQRCompoOpen, setQrCompoOpen] = useState(false)
    const [isEmergencyModalOpen, setEmergencyModalOpen] = useState(false);
    const [image, setImage] = useState("")
    const navigate = useNavigate()
    const handleCardClick = (id: string) => {
        navigate(`/profile/view/${id}`)
    }
    const handleCompleteProfile = (id: string) => {
        navigate(`/addBenFullDetails/${id}`)

        
    }

    useEffect(()=>{
         const fetchphoto=async()=>{
                    try{
                        const response = await getDocument(personalInfo?.image);
                       const blob = new Blob([response.data], { type: response.headers["content-type"] });
                      const  url = URL.createObjectURL(blob);
                       setImage(url)
                    }catch(err){
                        console.log(err)
                    }
            
                 }
                 if(personalInfo?.image){
                  fetchphoto()
        }

    },[personalInfo?.image])
    return (
        <>
            <Card
                className={Dashboardstyle.card}
                w="auto"
                cursor={"pointer"}
                borderRadius={"25px"}

            >
                <CardHeader onClick={() => { handleCardClick(id) }}>
                    <HStack justifyContent={"space-between"}>
                        <Flex gap="4">
                            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                                <Avatar
                                    name={personalInfo?.fullName || ""}
                                    src={image}
                                />
                                <Box>
                                    <Heading size="sm">
                                        {personalInfo?.fullName.toUpperCase() || ""}
                                    </Heading>
                                    <Text color={"#8c938c"}>
                                        {personalInfo?.relationshipToSubscriber?.toUpperCase() || ""}
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                    </HStack>
                </CardHeader>
                <CardBody>
                    <Stack
                        display={"flex"}
                        flexWrap={"wrap"}
                        gap={"20px"}
                        direction="row"
                        spacing={7}
                        align="center"
                    >
                        <Tooltip label="QR Code" fontSize="md">
                            <IconButton
                                onClick={() => setQrCompoOpen(true)}
                                aria-label="View Profile"
                                borderRadius={"50px"}
                                color={"#008470"}
                                bg="#EFF3F3"
                                variant="solid"
                            >
                                <BsQrCode />
                            </IconButton>
                        </Tooltip>
                        <Tooltip label="SOS Button" fontSize="md">
                            <IconButton
                                onClick={() => { setEmergencyModalOpen(true) }}
                                bg="#FCD3D0"
                                borderRadius={"100px"}
                                aria-label="Support"
                                size="md"
                                icon={<Image src="/Images/emergency-light.svg" />}
                            />
                        </Tooltip>
                    </Stack>
                    <Progress
                        top={"25px"}
                        value={(progress / 6) * 100}
                        size="xs"
                        colorScheme={
                            (progress / 6) * 100 > 60
                                ? "green"
                                : (progress / 6) * 100 > 40
                                    ? "yellow"
                                    : "red"
                        }
                    />
                </CardBody>

                <CardFooter
                    justify="space-between"
                    flexWrap="wrap"
                    alignItems={"center"}
                >
                    {/* --------- edit profile button ---------*/}

                    {isSmallerThan375 ? (
                        <Tooltip
                            label={Math.round((progress / 6) * 100) === 100
                                ? "Edit Profile"
                                : "Complete Profile"}
                        >
                            <IconButton
                                bg="#EFF3F3"
                                borderRadius={"20px"}
                                onClick={() => { }}
                                color={"#6F6F6F"}
                                fontSize="1.2rem"
                                aria-label="Edit Profile"
                            >
                                <EditIcon
                                    onClick={() => {
                                        handleCompleteProfile(id)
                                    }}
                                    color={Math.round((progress / 6) * 100) === 100
                                        ? "green"
                                        : "red"}
                                />
                            </IconButton>
                        </Tooltip>) :
                        <Button
                            onClick={() => {
                                handleCompleteProfile(id)
                            }}
                            color={"#6F6F6F"}
                            fontSize="sm"
                        >
                            {Math.round((progress / 6) * 100) === 100
                                ? "Edit Profile"
                                : "Complete Profile"}
                        </Button>}

                    {isSmallerThan375 ? (
                        <Tooltip label="View Profile" fontSize="md">
                            <IconButton
                                aria-label="View Profile"
                                onClick={() => { handleCardClick(id) }}
                                color={"#6F6F6F"}
                                fontSize="lg"
                                borderRadius={"20px"}
                            >
                                <ViewIcon color={"green"} />
                            </IconButton>
                        </Tooltip>
                    ) :
                        (< Button
                            onClick={() => handleCardClick(id)}
                            color={"#6F6F6F"}
                            fontSize="sm"
                        >
                            View Profile
                        </Button>)}
                    <Text color={"#6F6F6F"} fontSize="sm">
                        {Math.round((Number(progress) / 6) * 100) + " %"}
                    </Text>
                </CardFooter>
            </Card >

            {/* emegency modal will open */}
            {
                isEmergencyModalOpen && <AlertDialogCompo
                    benId={id}
                    mobile={
                        personalInfo.mobile == null
                            ? ""
                            : String(personalInfo.mobile.number)
                    }
                    lat={lat}
                    lng={lng}
                    isEmergencyOpen={isEmergencyModalOpen}
                    onEmergencyClose={() => setEmergencyModalOpen(false)}
                />
            }

            {/* Qr scanner */}
            {isQRCompoOpen && <QrCompo personalInfo={personalInfo} iSOpenQR={isQRCompoOpen} closeQR={() => { setQrCompoOpen(false) }} id={id} />}

        </>
    );
}
export default CardComponent;