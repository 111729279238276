import { Box, Flex, Image, Text } from "@chakra-ui/react";
import styles from "../Corporate.module.css";
import { useState, useEffect } from 'react';
import { LoginButtonWithDropdown } from "./LoginButtonWithDropdown.component";

export const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    
    useEffect(() => {
        const handleScroll = () => {
            // Check if user has scrolled more than 10 pixels
            if (window.scrollY > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    return (
        <Box 
            position="fixed" 
            top="0" 
            left="0" 
            right="0"
            zIndex="1000" 
            bg="white"
            transition="all 0.5s ease"
            height={isScrolled ? "80px" : "120px"}
            boxShadow={isScrolled ? "md" : "none"}
            width="100%"
        >
            <Flex 
                flexDirection={"row"} 
                justifyContent={"space-between"} 
                alignItems="center"
                height="100%"
                maxWidth="90%"
                margin="0 auto"
            >
                <Box 
                    transition="all 0.5s ease"
                    width={isScrolled ? "160px" : "240px"}
                >
                    <Image 
                        src="Images/logo.png" 
                        width={"100%"} 
                        height={"100%"} 
                        objectFit="contain"
                        alt="Company Logo"
                    />
                </Box>
                <Flex 
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={12}
                >
                    <Text className={styles.navTextStyle} _hover={{ color: "red" }}>
                        About us
                    </Text>
                    <Text className={styles.navTextStyle} _hover={{ color: "red" }}>
                        Request a demo
                    </Text>
                    <Text className={styles.navTextStyle} _hover={{ color: "red" }}>
                        Buy Subscription
                    </Text>
                    <Text className={styles.navTextStyle} _hover={{ color: "red" }}>
                        Career
                    </Text>
                    <Text className={styles.navTextStyle} _hover={{ color: "red" }}>
                        Blog
                    </Text>
                    <LoginButtonWithDropdown/>
                </Flex>
            </Flex>
        </Box>
    );
}