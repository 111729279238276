import { Box, Checkbox, Flex, Image, Text } from "@chakra-ui/react"
import { PhoneInput } from "./PhoneInput.component"
import { useState } from "react";
import { EmailInput } from "./EmailInput.component";

export const CorporateLoginComponent = () => {
    const [isChecked, setIsChecked] = useState(false);

    return (
        <Box width={"100%"} background={"rgba(254, 29, 143, 0.03)"}>
            <Flex flexDir={"row"} padding={"140px 13.5% 70px 13.5%"}>
                <Box flexBasis={"55%"}>
                    <Text
                        as="p"
                        fontFamily="Poppins"
                        fontWeight={300}
                        fontSize="32px"
                        lineHeight="39px"
                        letterSpacing="0%"
                    >
                        Medical Emergency
                    </Text>
                    <Text
                        fontFamily="Poppins"
                        fontWeight={700}
                        fontSize="32px"
                        lineHeight="39px"
                        letterSpacing="0%"
                    >
                        "Incident to Hospital Admission"
                    </Text>
                    <Text
                        as="p"
                        fontFamily="Poppins"
                        fontWeight={300}
                        fontSize="32px"
                        lineHeight="39px"
                        letterSpacing="0%"
                    >
                        Service Any
                        <Text
                            as={"span"}
                            fontFamily="Poppins"
                            fontWeight={300}
                            fontSize="32px"
                            lineHeight="39px"
                            letterSpacing="0%"
                            color="rgba(254, 29, 143, 1)">where</Text>
                    </Text>
                    <Text
                        fontFamily="Poppins"
                        fontWeight={400}
                        fontSize="20px"
                        lineHeight="120%"
                        letterSpacing="0%"
                        color="rgba(0, 0, 0, 0.5)"
                        mt={"20px"}
                    >
                        “Peace of Mind for you and your family”
                    </Text>
                    <Flex maxWidth={"500px"} flexDir={"row"} justifyContent={"center"} gap={"5%"} mt={"30px"}>
                        <Box
                            background="rgba(255, 255, 255, 1)"
                            borderRadius="15px"
                            boxShadow="0px 4px 17.1px 0px rgba(254, 29, 143, 0.08)"
                            width="48%" 
                            height="110px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            position="relative"
                            padding={"20px"}
                        >
                            <Image
                                src="Images/persistent.svg"
                                alt="Persistent Logo"
                                maxW="100%"
                                maxH="100%"
                                objectFit="contain"
                            />
                        </Box>
                        <Box
                            background="rgba(255, 255, 255, 1)"
                            borderRadius="15px"
                            boxShadow="0px 4px 17.1px 0px rgba(254, 29, 143, 0.08)"
                            width="48%" 
                            height="110px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            position="relative" // Alternative approach
                            padding={"20px"}
                        >
                            <Image 
                                src="Images/logo.png" 
                                alt="pococare Logo"
                                maxW="100%"
                                maxH="100%"
                                objectFit="contain"
                            />
                        </Box>
                    </Flex>
                    <Box
                        width="100%"
                        maxWidth="500px"
                        mt={"30px"}
                    >
                        <PhoneInput />
                    </Box>
                    <Box
                        maxWidth={"500px"}
                        mt={"10px"}
                    >
                        <Text
                            width={"fit-content"}
                            margin={"auto"}
                            padding={"5px"}
                            fontFamily="Poppins"
                            fontWeight={600}
                            fontSize="14px"
                            lineHeight="120%"
                            letterSpacing="0%"
                            textAlign={"center"}
                            color="#fff"
                            borderRadius={"50%"}
                            background="rgba(254, 29, 143, 1)"
                        >OR</Text>
                    </Box>
                    <Box
                        width="100%"
                        maxWidth="500px"
                        mt={"10px"}
                    >
                        <EmailInput />
                    </Box>
                    <Box mt={"20px"} ml={"5px"}>
                        <Checkbox
                            colorScheme="pink" // This sets the base color scheme (though we'll override the tick color)
                            isChecked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                            size="lg"
                            spacing="1rem"
                            sx={{
                              'span.chakra-checkbox__control': {
                                width: "32px",
                                height: "32px", 
                                borderRadius: "px",
                                border: "1px solid rgba(0, 0, 0, 0.28)",
                                boxShadow: "0px 8px 20.9px 0px rgba(0, 0, 0, 0.06)",
                                background: "#fff",
                                // When checked
                                '&[data-checked]': {
                                  background: "#fff",
                                  border:"1px solid rgba(0, 0, 0, 0.28)",
                                }
                              },
                              // Target the checkmark icon
                              'span.chakra-checkbox__control svg': {
                                color: "rgba(254, 29, 143, 1)", 
                                transform: "scale(1.5)"      // This sets the tick color
                              }
                            }}
                        >
                            <Text
                                as="p"
                                fontFamily="Poppins"
                                fontWeight={300}
                                fontSize="14px"
                                lineHeight="120%"
                                letterSpacing="0%"
                                bg="transparent"
                            >
                                By activating the account, I agree with the <Text as={"span"} color={"rgba(254, 29, 143, 1)"} textDecoration={"underline"}> "Terms of Usage"</Text>  <br/> and <Text as={"span"} color={"rgba(254, 29, 143, 1)"} textDecoration={"underline"}>"Privacy Policy"</Text></Text>
                        </Checkbox>
                    </Box>
                    <Box>
                        <Text
                            width={"fit-content"}
                            fontFamily={"Poppins"}
                            fontWeight={400}
                            fontSize={"16px"}
                            borderRadius={"92px"}
                            padding={"15px 40px"}
                            color={"#fff"}
                            background="rgba(254, 29, 143, 1)"
                            _hover={{background:"#12C395;"}}
                            transition={"all 0.5s ease"}
                            variant="solid"
                            lineHeight={"24px"}
                            mt="32px"
                            boxShadow={"rgba(254, 29, 143, 0.25) 0px 18px 43px 0px"}
                        >
                            Get OTP
                        </Text>
                    </Box>
                    <Text
                        fontFamily="Poppins"
                        fontWeight={400}
                        fontStyle="italic"
                        fontSize="14px"
                        lineHeight="120%"
                        letterSpacing="0%"
                        color="rgba(0, 0, 0, 0.5)"
                        mt={"30px"}
                    >
                        Note: Currently, this service benefit is available only for Persistent Nagpur Employees as a pilot initiative.
                    </Text>
                </Box>
                <Box flexBasis={"45%"}>
                    <Image src="Images/emergencyLifecycle.svg" 
                        alt="emergency lifecycle" 
                        width={"100%"} 
                        maxHeight={"566px"} 
                    />
                </Box>
            </Flex>
        </Box>
    )
}