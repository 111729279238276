import { Input, InputGroup, Menu, MenuButton, MenuList, MenuItem, Box, Flex, InputLeftElement, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import ReactCountryFlag from 'react-country-flag';
import { useState } from 'react';
import { createPortal } from 'react-dom';

const countryCodes = [
  { code: 'IN', dialCode: '+91', name: 'India' },
  { code: 'US', dialCode: '+1', name: 'United States' },
  { code: 'GB', dialCode: '+44', name: 'United Kingdom' },
  // Add more countries as needed
];

export const PhoneInput = () => {
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const [phoneNumber, setPhoneNumber] = useState('');

  return (
    <InputGroup>
      {/* Country Code Dropdown with Vertical Bar */}
      <Menu>
        {({ isOpen }) => (
          <>
            <InputLeftElement width="auto" left="24px" paddingTop="21px">
              <Flex align="center" gap="6px">
                <MenuButton
                  as={Box}
                  cursor="pointer"
                  bg="transparent"
                  p={0}
                  _hover={{ bg: 'transparent' }}
                  _active={{ bg: 'transparent' }}
                >
                  <Flex align="center">
                    <ReactCountryFlag
                      countryCode={selectedCountry.code}
                      svg
                      style={{ width: '20px', height: '16px' }}
                    />
                    <ChevronDownIcon
                      boxSize={4}
                      color="rgba(0, 0, 0, 0.31)"
                      ml={1}
                      transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
                      transition="transform 0.2s"
                    />
                  </Flex>
                </MenuButton>
                <Box
                  h="25px"
                  w="1px"
                  bg="rgba(0, 0, 0, 0.28)"
                  border= "1px solid rgba(223, 223, 223, 1)"
                />
              </Flex>
            </InputLeftElement>

            {/* Using createPortal for the MenuList */}
            {isOpen && createPortal(
              <MenuList
                maxH="300px"
                overflowY="auto"
                bg="white"
                boxShadow="0px 4px 14px rgba(0, 0, 0, 0.15)"
                zIndex="modal"
                border="1px solid #E2E8F0"
                minW="300px"
                mt={1}
              >
                {countryCodes.map((country) => (
                  <MenuItem
                    key={country.code}
                    onClick={() => setSelectedCountry(country)}
                    _hover={{ bg: 'gray.50' }}
                    _focus={{ bg: 'gray.50' }}
                    py={2}
                    px={4}
                  >
                    <ReactCountryFlag
                      countryCode={country.code}
                      svg
                      style={{ width: '20px', height: '15px', marginRight: '12px' }}
                    />
                    <Box>
                      <Text fontWeight="500">{country.name}</Text>
                      <Text fontSize="sm" color="gray.500">{country.dialCode}</Text>
                    </Box>
                  </MenuItem>
                ))}
              </MenuList>,
              document.body
            )}
          </>
        )}
      </Menu>

      {/* Phone Number Input */}
      <Input
        type="tel"
        placeholder="Phone number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        pl="85px"
        paddingTop={"21px"}
        paddingBottom={"14px"}
        focusBorderColor="green.500"
        background={"#fff"}
        _hover={{ borderColor: 'gray.500' }}
        border="1px solid rgba(0, 0, 0, 0.28)"
        borderRadius={"10px"}
        boxShadow="0px 8px 20.9px 0px rgba(254, 29, 143, 0.13)"
        fontFamily="Poppins"
        fontWeight={400}
        fontSize="16px"
        lineHeight="24px"
        height={"auto"}
      />
    </InputGroup>
  )
}