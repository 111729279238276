import { Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react"
import { BiSolidPhoneCall } from "react-icons/bi"
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa"
import styles from "../Corporate.module.css";

export const Footer = () => {
    return (
        <Box width={"100%"} background={"rgba(68, 149, 196, 0.06)"} margin={"auto"}>
            <Box width={"95%"} margin={"auto"} paddingTop={"70px"} paddingBottom={"45px"}>
                <HStack spacing={10} justifyContent={"space-evenly"} alignItems="flex-start">
                    <VStack alignItems={'flex-start'} gap={5}>
                        <Image src={"Images/logo.png"} width={"250px"} height={"94px"} marginBottom={"10px"} />
                        <HStack alignItems={'flex-start'} gap={2} py={"10px"}>
                            <Image src={"Images/facebook.svg"} width={"33px"} height={"32px"} />
                            <Image src={"Images/instagram.svg"} width={"33px"} height={"32px"} />
                            <Image src={"Images/linkedIn.png"} width={"33px"} height={"32px"} />
                            <Image src={"Images/youtube.svg"} width={"33px"} height={"32px"} />
                        </HStack>
                        <Image src={"Images/google-playstore.svg"} width={"90%"} height={"100%"} py={"15px"}></Image>
                    </VStack>
                    <VStack alignItems={'flex-start'} spacing={"0px"}>
                        <Text className={styles.footerListMainText}>About Us</Text>
                        <Text className={styles.footerListSubText}>Our Founders</Text>
                        <Text className={styles.footerListSubText}>Awards & recognition</Text>
                        <Text className={styles.footerListSubText}>News & Media</Text>
                    </VStack>
                    <VStack alignItems={'flex-start'} spacing={"0px"}>
                        <Text className={styles.footerListMainText}>Pococare Advantages</Text>
                        <Text className={styles.footerListSubText}>End To End</Text>
                        <Text className={styles.footerListSubText}>Beyond Ambulance</Text>
                        <Text className={styles.footerListSubText}>Personalized Readiness</Text>
                        <Text className={styles.footerListSubText}>Intelligent Ambulance Dispatch</Text>
                        <Text className={styles.footerListSubText}>Intelligent Hospital Finder</Text>
                        <Text className={styles.footerListSubText}>Data & Cyber Security</Text>
                    </VStack>
                    <VStack alignItems={'flex-start'} spacing={"0px"}>
                        <Text className={styles.footerListMainText}>Useful Links</Text>
                        <Text className={styles.footerListSubText}>Contact Us</Text>
                        <Text className={styles.footerListSubText}>Terms of Usage</Text>
                        <Text className={styles.footerListSubText}>Privacy Policy</Text>
                    </VStack>
                    <VStack alignItems={'flex-start'} spacing={"0px"}>
                        <Text className={styles.footerListMainText}>Pococare</Text>
                        <Text className={styles.footerListSubText}>Our Vision</Text>
                        <Text className={styles.footerListSubText}>Our Mission</Text>
                        <Text className={styles.footerListSubText}>Our Values - Pocoways</Text>
                    </VStack>
                    <VStack alignItems={'flex-start'} spacing={"0px"}>
                        <Text className={styles.footerListMainText}>Contact Us</Text>
                        <Flex align="center" gap={2}>
                            <FaMapMarkerAlt color="black" size={15} />
                            <Text className={styles.footerListSubText}>Bengaluru, India</Text>
                        </Flex>
                        <Flex align="center" gap={2}>
                            <BiSolidPhoneCall color="black" size={15} />
                            <Text className={styles.footerListSubText}>+91 9876543210</Text>
                        </Flex>
                        <Flex align="center" gap={2}>
                            <FaEnvelope color="black" size={15} />
                            <Text className={styles.footerListSubText}>info@pococare.com</Text>
                        </Flex>
                    </VStack>
                </HStack>
                <Box>
                    <Text
                        as="p"
                        fontFamily={"Poppins"}
                        fontSize={"13px"}
                        fontWeight="300"
                        textAlign="center"
                        color="rgba(0, 0, 0, 0.51)"
                        lineHeight={"36px"}
                        marginTop={"40px"}
                    >
                        © 2025 Copyright POCOCARE. All Rights Reserved
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}
