import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Box,
    Image,
    Text,
    Button,
    Flex,
    useMediaQuery
} from "@chakra-ui/react"; // Import necessary Chakra UI components
import { QRCode } from 'react-qrcode-logo';
import Dashboardstyle from '../../DashBoard.module.css';
import { PersonalInfo } from '../../../../Helper/BenInterface';
import html2canvas from 'html2canvas';
import apiConfig from '../../../../apiConfig/apiConfig';
import { TATA_TELE_NUMBER1, TATA_TELE_NUMBER2 } from '../../../../Helper/constants';
import { toBlob } from "html-to-image";
import { FaShareAlt } from "react-icons/fa";


interface QrProp {
    personalInfo: PersonalInfo;
    iSOpenQR: boolean;
    closeQR: () => void;
    id:string
}

const QrCompo: React.FC<QrProp> = ({ personalInfo, iSOpenQR, closeQR, id }) => {
    const [styleShow, setStyleShow] = useState(false);
    const [isMobileScreen] = useMediaQuery("(max-width: 768px)");
   const handleShare = async () => {
    setStyleShow(true);
  try {
    const imageElement = document.querySelector(".download") as HTMLElement; //  QR Code container
    const blob = await toBlob(imageElement);

    if (!blob) {
      alert("Failed to generate QR Code!");
      return;
    }
    const file = new File([blob], "QRCode.png", { type: blob.type });
    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      await navigator.share({
        title: "QR Code",
        text: "Scan this QR Code",
        files: [file], // Share as a file
      });
    } else {
      alert("Sharing not supported on this device. Download the image instead.");
    }
  } catch (err) {
    console.error("Error sharing:", err);
  }
};

    const handlePrintQrCode = () => {
        let qrCodeContainer;
        setTimeout(async () => {
          qrCodeContainer = document.querySelector(".download") as HTMLElement;
          if (!qrCodeContainer) {
            console.error("QR code container not found");
            return;
          }
      
          await html2canvas(qrCodeContainer, {
            scrollX: 0,
            scrollY: -window.scrollY,
            useCORS: true,
          }).then((canvas) => {
            const link = document.createElement("a");
            const dataURL = canvas.toDataURL("image/jpeg", 1);
            link.href = dataURL;
            link.download = personalInfo?.fullName + ".jpeg";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }, 5);
        setStyleShow(true);
      
        // Capture the HTML as an image
    };
      
    return (
        <Modal
            isCentered
            motionPreset="slideInBottom"
            onClose={closeQR}
            isOpen={iSOpenQR}
        >
            <ModalOverlay />
            <ModalContent>
                <div className="download">
                    <ModalHeader textAlign={"center"}>
                        {personalInfo?.fullName?.toUpperCase()}
                    </ModalHeader>
                    <ModalBody>
                        <Box className={Dashboardstyle.qr_container}>
                            <Box
                                className={
                                    styleShow
                                        ? Dashboardstyle.bottom_Border
                                        : Dashboardstyle.poco_Logo_wrapper
                                }
                            >
                                <Box className={Dashboardstyle.poco_Logo}>
                                    <Image src="/Images/warnIcon.png" />
                                </Box>
                                <Box className={Dashboardstyle.poco_Logo}>
                                    <Image src="/Images/NewLogo.png" />
                                </Box>
                            </Box>
                            <Box
                                className={
                                    styleShow
                                        ? Dashboardstyle.bottom_Border
                                        : Dashboardstyle.scanner_Text
                                }
                            >
                                <Text className={Dashboardstyle.text_Style}>
                                    Please scan if you find me in any Medical Emergency
                                </Text>
                            </Box>
                            <Flex justifyContent={"center"} >
                                {/* QRCode component */}
                                {/* <QRCode
                                    value={`${apiConfig.api.frontEndURL || "https://www.pococare.com"}/trigger-emergency/${id || ''}`}
                                    id="canvas"
                                    size={250}
                                    level={"H"}
                                    includeMargin={true}
                                    imageSettings={{
                                        src: "/Images/transparentLogo.png",
                                        x: undefined,
                                        y: undefined,
                                        height: 40,
                                        width: 40,
                                        excavate: true,
                                    }}
                                /> */}
                                <QRCode
                                   id="canvas"
                                    eyeColor={"black"}
                                    eyeRadius={2}
                                    logoImage={"/Images/transparentLogo.png"}
                                    size={250}
                                    ecLevel="L"
                                    logoWidth={40}
                                    logoHeight={40}                                    
                                    logoPadding={8}
                                    logoPaddingStyle={"square"}
                                    qrStyle="dots"
                                    fgColor="black"
                                    logoOpacity={10}
                                    value={`${apiConfig.api.frontEndURL || "https://www.pococare.com"}/trigger-emergency/${id || ''}`}
                                    />
                            </Flex>
                            <Box
                                className={
                                    styleShow
                                        ? Dashboardstyle.bottom_Border
                                        : Dashboardstyle.scanner_Text
                                }
                            >
                                <Text className={Dashboardstyle.text_Style}>Or Call</Text>
                            </Box>
                            <Box className={Dashboardstyle.center_PhoneNumber}>
                                <Text className={Dashboardstyle.phone_Number}>{ TATA_TELE_NUMBER1 }</Text>
                                <Text
                                    marginBottom={styleShow ? "10px" : ""}
                                    className={Dashboardstyle.phone_Number}
                                >
                                    { TATA_TELE_NUMBER2 }
                                </Text>
                            </Box>
                        </Box>
                    </ModalBody>
                </div>
                <ModalFooter>
                    <Box className={Dashboardstyle.footer}>
                        <Box
                            
                            cursor={"pointer"}
                            className={Dashboardstyle.text_Style}
                        >
                            <Button onClick={handlePrintQrCode}>Download</Button>
                            {isMobileScreen&&<Button marginLeft={"1rem"} onClick={handleShare} leftIcon={<FaShareAlt size={20}  />}>Share</Button>}
                        </Box>
                        <Box>
                            <Button onClick={closeQR}>Close</Button>
                        </Box>
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default QrCompo;
